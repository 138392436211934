import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import cn from 'classnames'
import _orderBy from 'lodash/orderBy'
import _take from 'lodash/take'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import SubNavigation from '../components/SubNavigation'
import LeadSpot from '../components/primary/LeadSpot'
import LeadStreamer from '../components/primary/LeadStreamer'
import ExpandableImage from '../components/ExpandableImage'
import BenefitsToYou from '../components/primary/BenefitsToYou'
import TutorialReel from '../components/primary/TutorialReel'
import PriceTable from '../components/primary/PriceTable'
import CompanyTabs from '../components/primary/CompanyTabs'
import TestimonialsHalf from '../components/TestimonialsHalf'
import CallToAction from '../components/primary/CallToAction'
import NewsArticleLink from '../components/articles/Listings/NewsArticleLink'
import EditorialArticleLink from '../components/articles/Listings/EditorialArticleLink'
import Newsletter from '../components/Newsletter'

import { isEven } from '../utils/num'

const CaterersPage = () => {
  const {
    markdownRemark,
    allDatoCmsTestimonial,
    newsArticles,
    editorialArticles,
  } = useStaticQuery(graphql`
    query CaterersPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/caterers" } }) {
        frontmatter {
          title
          description
          path
          ...SubNavigationFragment
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          section3 {
            title
            text
            image {
              modal: childImageSharp {
                ...SharpImageModalFragment
              }
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          ...BenefitsToYouFragment
          benefitsCustomers {
            blurbs {
              title
              text
              icon
            }
          }
          preSubscription {
            blurbs {
              title
              text
              image {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageBorder
            }
          }
          proSubscription {
            blurbs {
              title
              text
              image {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageBorder
            }
          }
          ...TutorialReelFragment
          ...PricingFragment
          ...CompanyTabsFragment
          ...CallToActionFragment
        }
      }
      allDatoCmsTestimonial(
        filter: {
          categories: { elemMatch: { title: { eq: "Caterer/Retailer" } } }
        }
        limit: 2
      ) {
        edges {
          node {
            ...TestimonialsFragment
          }
        }
      }
      newsArticles: allDatoCmsNewsArticle(
        filter: { tags: { elemMatch: { title: { in: "Caterers" } } } }
        sort: { fields: displayDate, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            __typename
            title
            slug
            relPath
            category
            categoryLink
            pubDateTime
            heroImage {
              url
              alt
              title
              customData
            }
            thumbnailImage {
              url
              alt
              title
              customData
            }
          }
        }
      }
      editorialArticles: allDatoCmsEditorialArticle(
        filter: { tags: { elemMatch: { title: { in: "Caterers" } } } }
        sort: { fields: displayDate, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            __typename
            title
            slug
            relPath
            category
            categoryLink
            pubDateTime
            heroImage {
              url
              alt
              title
              customData
            }
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter
  const testimonials = allDatoCmsTestimonial.edges
  const allArticles = _orderBy(
    [...newsArticles.edges, ...editorialArticles.edges],
    'node.pubDateTime',
    'desc'
  )
  const latestArticles = _take(allArticles, 3)

  return (
    <Page className="p-forCaterers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <SubNavigation subNav={pageData.subNav} />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        text={pageData.lead.spot.text}
        image={pageData.lead.spot.image}
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        tag={pageData.lead.streamer.tag}
        title={pageData.lead.streamer.title}
        text={pageData.lead.streamer.text}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
      />

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left">
              {pageData.section3.map((blurb, i) => (
                <div key={`section3-${i}`} className="row">
                  <div
                    className={`col-12 col-md-6 mb-5 ${
                      isEven(i) ? 'order-md-2' : ''
                    } d-flex flex-column justify-content-center`}
                  >
                    <h2 className="mb-4">{blurb.title}</h2>
                    <div className="c-postStreamer__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 px-5 text-center">
                    <ExpandableImage
                      imageData={blurb.image.modal}
                      bottomRight={!isEven(i)}
                    >
                      <Img
                        fluid={blurb.image.childImageSharp.fluid}
                        title={blurb.title}
                        alt={blurb.title}
                        className="c-postStreamer__image img-fluid mx-3"
                      />
                    </ExpandableImage>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <BenefitsToYou
        upper={pageData.benefitsYou.blurbsOne}
        lower={pageData.benefitsYou.blurbsTwo}
      />

      <section
        id="benefits-to-your-customers"
        className="c-section c-section--forBenefits"
      >
        <div className="c-section__outer container">
          <div
            className="c-section__inner"
            style={{ padding: '6.25em 0 5.875em' }}
          >
            <div className="c-forCustBenefits row">
              {pageData.benefitsCustomers.blurbs.map((blurb) => (
                <div key={blurb.title} className="col-12 col-md-4">
                  <img
                    src={`/images/icons/${blurb.icon}.png`}
                    srcSet={`/images/icons/${blurb.icon}.svg`}
                    alt={`${blurb.title} icon`}
                    className="c-forCustBenefits__icon img-fluid"
                  />
                  <p className="c-forCustBenefits__title">{blurb.title}</p>
                  <p className="c-forCustBenefits__text">{blurb.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section
        id="premium-subscription"
        className="c-section c-section--forSubscription c-section--forSubscription--pre"
      >
        <div className="c-section__outer container">
          <div className="c-section__tag">Premium subscription</div>
          <div className="c-section__inner">
            <div className="c-forSubscription">
              <div className="c-forSubscription__header">
                <h1>
                  Take control with a <strong>premium subscription</strong>
                </h1>
              </div>

              {pageData.preSubscription.blurbs.map((blurb, i) => (
                <div
                  key={blurb.title}
                  className={`c-forSubscriptionCard ${
                    !isEven(i) ? 'c-forSubscriptionCard--reversed' : ''
                  }`}
                >
                  <div className="c-forSubscriptionCard__block c-forSubscriptionCard__block--text">
                    <h5 className="c-forSubscriptionCard__header">
                      {blurb.title}
                    </h5>
                    <div className="c-forSubscriptionCard__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>

                  <div className="c-forSubscriptionCard__block c-forSubscriptionCard__block--image">
                    <Img
                      fluid={blurb.image.childImageSharp.fluid}
                      title={blurb.title}
                      alt={blurb.title}
                      className={`c-forSubscriptionCard__image img-fluid ${
                        blurb.imageBorder && 'has-shadowBorder'
                      }`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section
        id="pro-subscription"
        className="c-section c-section--forSubscription c-section--forSubscription--pro"
      >
        <div className="c-section__outer container">
          <div className="c-section__tag c-section__tag--noLine">
            Pro subscription
          </div>
          <div className="c-section__inner">
            <div className="c-forSubscription">
              <div className="c-forSubscription__header">
                <h1>
                  Boost your catering power with a{' '}
                  <strong>pro subscription</strong>
                </h1>
              </div>

              {pageData.proSubscription.blurbs.map((blurb, i) => (
                <div
                  key={blurb.title}
                  className={`c-forSubscriptionCard ${
                    !isEven(i) ? 'c-forSubscriptionCard--reversed' : ''
                  }`}
                >
                  <div className="c-forSubscriptionCard__block c-forSubscriptionCard__block--text">
                    <h5 className="c-forSubscriptionCard__header">
                      {blurb.title}
                    </h5>
                    <div className="c-forSubscriptionCard__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>

                  <div className="c-forSubscriptionCard__block c-forSubscriptionCard__block--image">
                    <Img
                      fluid={blurb.image.childImageSharp.fluid}
                      title={blurb.title}
                      alt={blurb.title}
                      className={`c-forSubscriptionCard__image img-fluid ${
                        blurb.imageBorder && 'has-shadowBorder'
                      }`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <TutorialReel
        header={pageData.subVideos.header}
        buttonText={pageData.subVideos.buttonText}
        buttonLink={pageData.subVideos.buttonLink}
        filter={pageData.subVideos.filter}
        videos={pageData.subVideos.videos}
      />

      <PriceTable pricing={pageData.pricing} />

      <CompanyTabs companiesData={pageData.companies} />

      <TestimonialsHalf testimonials={testimonials} />

      <CallToAction ctaData={pageData.signUpCta} />

      <section id="latest-updates">
        <div className="container">
          <div className="row py-5 c-newsItemWithHeader">
            {latestArticles.map(({ node }, i) => (
              <div
                key={node.slug}
                className={cn('col-6 col-lg-4', {
                  'd-none d-lg-block': i === 2,
                })}
              >
                {node.__typename === 'DatoCmsNewsArticle' && (
                  <NewsArticleLink article={node} />
                )}
                {node.__typename === 'DatoCmsEditorialArticle' && (
                  <EditorialArticleLink article={node} />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default CaterersPage
